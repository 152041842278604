<template>
  <div id="app">
    <!-- 路由的出入口，路由的内容没那个将被显示在这里 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />

  </div>
</template>

<script>
import DevicePixelRatio from '@/utils/devicePixelRatio'
export default {
  name: 'App',
  created() {
    new DevicePixelRatio().init()
  }
}
</script>

<style>
body {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    zoom: 0 !important;
    /*background-color: #F5F5F5;*/
}
::-webkit-scrollbar {
    display: none;
}
#app {

}
</style>
