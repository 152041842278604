import Vue from 'vue' // 引入 Vue
import VueRouter from 'vue-router'

Vue.use(VueRouter) // 安装插件

export const constantRouterMap = [
  // 配置默认的路径，默认显示登录页
  {
    path: '/',
    meta: { keepAlive: false },
    component: () => import('@/views/home/home') },
  {
    path: '/articleDetail',
    meta: { keepAlive: true },
    component: (resolve) => require(['@/views/article/articleDetail'], resolve)
  },
  {
    path: '/articleList',
    meta: { keepAlive: true },
    component: (resolve) => require(['@/views/article/articleList'], resolve)
  },
  {
    path: '/markList',
    meta: { keepAlive: false },
    component: (resolve) => require(['@/views/markList/markHome'], resolve)
  },
  {
    path: '/chatHome',
    component: (resolve) => require(['@/views/customerChat/chatHome'], resolve)
  },
  {
    path: '/mobileChatHome',
    component: (resolve) => require(['@/views/customerChat/mobileChatHome'], resolve)
  },
  {
    path: '/propertyHome',
    meta: { keepAlive: false },
    component: (resolve) => require(['@/views/property/propertyHome'], resolve)
  },
  {
    path: '/softwareHome',
    meta: { keepAlive: false },
    component: (resolve) => require(['@/views/software/softwareHome'], resolve)
  },
  {
    path: '/legalHome',
    meta: { keepAlive: false },
    component: (resolve) => require(['@/views/legal/legalHome'], resolve)
  },
  {
    path: '/creatityHome',
    meta: { keepAlive: false },
    component: (resolve) => require(['@/views/creativity/creatityHome'], resolve)
  }

]

export default new VueRouter({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap // 指定路由列表
})
